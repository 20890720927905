import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Container from '../../../components/Container';
import SubmitButton from '../../../components/Button';
import style from './Motivados.module.css';
import { FaFileExcel, FaCheckSquare, FaSquare } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import Modal from 'react-modal';
import Select, {StylesConfig} from 'react-select';
import makeAnimated from 'react-select/animated';
import modalStyle from './Modal.module.css'; 
import ImageUpload from '../../../components/ImgUpload/ImgUpload.jsx';

registerLocale('pt-BR', ptBR);

Modal.setAppElement('#root');

const Motivados = () => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      border: '1px solid #7BA776',
      boxShadow: 'none', // Remove a sombra padrão
      '&:hover': {
        border: '1px solid #2D99AA', // Mudança de cor ao passar o mouse
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Para garantir que o menu fique acima de outros elementos
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#2D99AA' : 'white', // Cor de fundo quando a opção está em foco
      color: state.isFocused ? 'white' : 'black', // Cor do texto quando em foco
      ':active': {
        backgroundColor: '#2D99AA', // Cor de fundo quando a opção é selecionada
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      border: '1px solid #7BA776',
      borderRadius: '7px' // Define o fundo azul escuro para itens selecionados
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '',
      borderRadius: '7px',
      transition: 'all ease 0.3s', // Define o fundo azul escuro para itens selecionados
      ':hover': {
        backgroundColor: '#2D99AA', // Fundo azul escuro no hover do "x" para remover
        color: 'white',
      },
    }),
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [motivados, setMotivados] = useState([]);
  const [bercospa, setBercoSPA] = useState([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState('');
  const [berco, setBerco] = useState({});
  const [nomeacao, setNomeacao] = useState(null);
  const [justificativa, setJustificativa] = useState(`EQUIPE DE COLABORADORES DA EMPRESA ${empresaSelecionada} EM ATENDIMENTO A OPERAÇÃO PORTUÁRIA.`);
  const [navios, setNavios] = useState([]);
  const [mostrarNavios1, setMostrarNavios1] = useState(false);
  const [mostrarNavios2, setMostrarNavios2] = useState(false);
  const [mostrarNavios3, setMostrarNavios3] = useState(false);
  const [mostrarNavios4, setMostrarNavios4] = useState(false);
  const [operacao1, setOperacao1] = useState('');
  const [operacao2, setOperacao2] = useState('');
  const [operacao3, setOperacao3] = useState('');
  const [operacao4, setOperacao4] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [arqueacao, setArqueacao] = useState(false);
  const [isMotivarButtonHidden, setIsMotivarButtonHidden] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [notFoundCpfs, setNotFoundCpfs] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [motivadoModal, setMotivadoModal] = useState(false)
  const [motivadoData, setMotivadoData] = useState({})
  const [filteredMotivados, setFilteredMotivados] = useState([])
  const [isNomeacaoRequired, setIsNomeacaoRequired] = useState(true)


  useEffect(() => {
    if (berco.length === 2) {
      setArqueacao(false);
    }
  }, [berco]);
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    Axios.get(`/api/motivacao/motivados`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        console.log(response.data)
        setMotivados(response.data);
        setEmpresaSelecionada(response.data[0].NOME_REDUZIDO);
      })
      .catch(error => {
        console.error("Erro ao buscar dados: ", error);
        enqueueSnackbar('Erro ao buscar dados', { variant: 'error' });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    Axios.get(`/api/bercospa`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setBercoSPA(response.data);
      })
      .catch(error => {
        console.error("Erro ao buscar dados: ", error);
        enqueueSnackbar('Erro ao buscar dados', { variant: 'error' });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    Axios.get(`/api/operacao/operando`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setNavios(response.data);
      })
      .catch(error => {
        console.error("Erro ao buscar dados: ", error);
        enqueueSnackbar('Erro ao buscar dados', { variant: 'error' });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (startDate && endDate) {
      if (calculateDateDifference(startDate, endDate) > 5) {
        enqueueSnackbar('O período não pode ser superior a 5 dias!', { variant: 'error' });
        setEndDate(null);
      }
    }
  }, [startDate, endDate, enqueueSnackbar]);

  useEffect(() => {
    filtrarMotivados();
  }, [motivados, empresaSelecionada]);
  
  const filtrarMotivados = () => {
    setFilteredMotivados(
      motivados.filter(
        item => empresaSelecionada === '' || item.NOME_REDUZIDO === empresaSelecionada
      )
    );
  };

  const formatarCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const empresasUnicas = [...new Set(motivados.map(item => item.NOME_REDUZIDO))];

  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados();
    }
  };

  const validaDados = () => {
    if (!startDate || !endDate) {
      showAlert('Selecione o período de datas!', 'error');
      return;
    }
    
    if (!nomeacao && isNomeacaoRequired) {
      showAlert('Envie a imagem da nomeação!', 'error');
      return;
    }
    if (!berco.length) {
      showAlert('Selecione um berço!', 'error');
      return;
    }
    if (arqueacao) {
      if (mostrarNavios1) {
        motivar();
      } else {
        showAlert('Selecione pelo menos um navio para a arqueação!', 'error');
      }
      return;
    }




    motivar();
  };

  const onUpload = (img) => {
    console.log('Imagem sendo salva...')
    console.log(img)
    setNomeacao(img)
  }

  const calculateDateDifference = (start, end) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((end - start) / oneDay));
    return diffDays;
  };

  const filtrarMotivadosParaEnvio = (empresaSelecionada) => {
    return motivados
      .filter(item => item.STATUS_MOTIVADO === 'A' && item.NOME_REDUZIDO === empresaSelecionada);
  };

  const toggleMotivadoStatus = (motivadoId, currentStatus) => {
    const updatedStatus = currentStatus === 'A' ? 'I' : 'A';

    const updatedMotivados = motivados.map(motivado =>
      motivado.COD_MOTIVADO === motivadoId ? { ...motivado, STATUS_MOTIVADO: updatedStatus } : motivado
    );
    setMotivados(updatedMotivados);

    Axios.post(`/api/motivado/status/update`, { motivadoId, updatedStatus }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  };

  const renderStatusSwitch = (motivado) => {
    return (
      <label className={style.switch}>
        <input
          type="checkbox"
          className={style.inputhidden}
          checked={motivado.STATUS_MOTIVADO === 'A'}
          onChange={() => toggleMotivadoStatus(motivado.COD_MOTIVADO, motivado.STATUS_MOTIVADO)}
        />
        <span className={`${style.slider} ${style.statusSlider}`}></span>
      </label>
    );
  };

  const toggleSelectAll = () => {
    const empresa = empresaSelecionada; // Obtenha a empresa selecionada
    console.log(empresaSelecionada)

    // Determine o status atual predominante para a empresa selecionada
    const currentStatus = motivados.some(motivado => motivado.NOME_REDUZIDO === empresa && motivado.STATUS_MOTIVADO === 'A') ? 'A' : 'I';

    // Determine o novo status baseado no status atual
    const updatedStatus = currentStatus === 'A' ? 'I' : 'A';

    // Atualize o status dos motivados apenas da empresa selecionada
    const updatedMotivados = motivados.map(motivado => ({
      ...motivado,
      STATUS_MOTIVADO: motivado.NOME_REDUZIDO === empresa ? updatedStatus : motivado.STATUS_MOTIVADO
    }));

    // Atualize o estado dos motivados e a seleção de todos
    setMotivados(updatedMotivados);
    setSelectAll(!selectAll);

    // Envie a atualização para o backend
    Axios.post(`/api/motivado/status/update/all`, { empresa, updatedStatus }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(() => {
        console.log("Status atualizado com sucesso.");
      })
      .catch(error => {
        console.error("Erro ao atualizar status: ", error);
        showAlert('Erro ao atualizar status', 'error');
      });
  };

  const handleBercosChange = (bercos) => {
    setBerco(bercos)
    console.log(bercos)
  }

  const ativarCPFs = (cpfList) => {
    if (!cpfList.trim()) {
      return;
    }

    const cpfArray = cpfList.split(/[,;]/).map(cpf => cpf.trim().replace(/[.-]/g, '')); // Remove pontos e traços

    const invalidCpf = cpfArray.some(cpf => /\D/.test(cpf)); // Verifica se há caracteres não numéricos

    if (invalidCpf) {
      showAlert('A lista de CPFs não pode conter letras ou caracteres especiais!', 'error');
      return;
    }

    Axios.post('/api/motivado/ativar', { cpfs: cpfArray, empresa: empresaSelecionada },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(response => {
        const notFoundCpfs = response.data.result;
        console.log(notFoundCpfs)
        if (notFoundCpfs && notFoundCpfs.length > 0) {
          const formattedNotFoundCpfs = notFoundCpfs.map(cpf => formatarCPF(cpf)); // Formatar CPFs não encontrados
          setNotFoundCpfs(formattedNotFoundCpfs); // Armazenar CPFs não encontrados
          setModalIsOpen(true); // Abrir modal
        } else {
          showAlert('CPFs ativados com sucesso!', 'success');
        }
        setMotivados(prevMotivados =>
          prevMotivados.map(motivado =>
            cpfArray.includes(motivado.CPF_MOTIVADO)
              ? { ...motivado, STATUS_MOTIVADO: 'A' }
              : { ...motivado, STATUS_MOTIVADO: 'I' }
          )
        );
      })
      .catch(error => {
        console.error('Erro ao ativar CPFs: ', error);
        showAlert('Erro ao ativar CPFs', { variant: 'error' });
      });
  };

  const motivar = async() => {
    const dataInicialFormatada = startDate.toISOString().split('T')[0];
    const dataFinalFormatada = endDate.toISOString().split('T')[0];

    const cpfMotivados = filtrarMotivadosParaEnvio(empresaSelecionada).map(item => item.CPF_MOTIVADO).join(';');
    console.log(cpfMotivados)

    let justificativaFinal = justificativa;
    const operacoes = [operacao1, operacao2, operacao3, operacao4].filter(op => op).join(', ');

    if (operacoes) {
      justificativaFinal += ` Com acesso a bordo a(os) navio(s) (${operacoes})`;
    }

    const formData = new FormData();

    // Adicione os dados de motivação ao FormData
    formData.append('bercos', JSON.stringify(berco));
    formData.append('cpfMotivados', cpfMotivados);
    formData.append('empresa', empresaSelecionada);
    formData.append('dataInicial', dataInicialFormatada);
    formData.append('dataFinal', dataFinalFormatada);
    formData.append('usuarioCadastro', '1');
    formData.append('justificativa', justificativaFinal);
    formData.append('arqueacao', arqueacao ? 'S' : 'N');
    formData.append('operacao1', operacao1);
    formData.append('operacao2', operacao2);
    formData.append('operacao3', operacao3);
    formData.append('operacao4', operacao4);

    if (nomeacao) { 
      formData.append('file', nomeacao);
    }
  
    setIsMotivarButtonHidden(true);
  
    try {
      const response = await Axios.post('/api/motivacao/registrar', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'  // Não é estritamente necessário, Axios configura isso automaticamente
        }
      });
  
      showAlert('Ação de motivar realizada com sucesso!', 'success');
      setTimeout(() => setIsMotivarButtonHidden(false), 4000);
    } catch (error) {
      const mensagemErro = error.response?.data?.message || 'Erro desconhecido ao realizar a ação de motivar.';
      console.error(mensagemErro);
      showAlert(mensagemErro, 'error');
      setTimeout(() => setIsMotivarButtonHidden(false), 4000);
    }
  };

  const exportToExcel = () => {
    const dataForExport = filtrarMotivadosParaEnvio(empresaSelecionada).map(motivado => ({
      'Nome Motivado': motivado.NOME_MOTIVADO,
      'CPF Motivado': formatarCPF(motivado.CPF_MOTIVADO),
      'E-Mail': motivado.EMAIL_MOTIVADO,
      'Empresa': motivado.NOME_REDUZIDO,
      'Status': motivado.STATUS_MOTIVADO
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataForExport);

    const wscols = [
      { wch: 30 },
      { wch: 15 },
      { wch: 30 },
      { wch: 25 },
      { wch: 15 }
    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.sheet_add_aoa(worksheet, [["Lista de Motivados"]], { origin: 0 });
    worksheet['!cols'][0].wch = 50;

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_address = XLSX.utils.encode_cell({ c: C, r: 0 });
      if (!worksheet[cell_address]) continue;
      worksheet[cell_address].s = {
        font: {
          bold: true,
          sz: 14,
          color: { rgb: "FFFFFF" }
        },
        fill: {
          fgColor: { rgb: "4F81BD" }
        },
        alignment: {
          vertical: "center",
          horizontal: "center"
        }
      };
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Motivados");

    const fileName = "ListaDeMotivados.xlsx";

    XLSX.writeFile(workbook, fileName);
  };

  const toggleMostrarNavios1 = () => {
    if (mostrarNavios1) {
      setMostrarNavios2(false);
      setMostrarNavios3(false);
      setMostrarNavios4(false);
    }
    setMostrarNavios1(!mostrarNavios1);
  };

  const toggleMostrarNavios2 = () => {
    if (mostrarNavios2) {
      setMostrarNavios3(false);
      setMostrarNavios4(false);
    }
    setMostrarNavios2(!mostrarNavios2);
  };

  const toggleMostrarNavios3 = () => {
    if (mostrarNavios3) {
      setMostrarNavios4(false);
    }
    setMostrarNavios3(!mostrarNavios3);
  };

  const toggleMostrarNavios4 = () => {
    setMostrarNavios4(!mostrarNavios4);
  };

  const handleNomeacaoReq = () => {
    setIsNomeacaoRequired(!isNomeacaoRequired)
  }

  useEffect(() => {
    let justificativaFinal = `Equipe de colaboradores da empresa ${empresaSelecionada} em atendimento a operação portuária.`;
    const operacoes = [operacao1, operacao2, operacao3, operacao4].filter(op => op).join(', ');

    if (operacoes) {
      justificativaFinal += ` Com acesso a bordo a(os) navio(s) (${operacoes})`;
    }

    if (arqueacao) {
      justificativaFinal += ' - ARQUEAÇÃO';
    }

    setJustificativa(justificativaFinal);
  }, [empresaSelecionada, operacao1, operacao2, operacao3, operacao4, arqueacao]);

  const handleArqueacaoChange = (e) => {
    if (berco.length > 1) {
      showAlert("Não pode selecionar Arqueação quando dois berços estão selecionados.", "error");
      return;
    }
    setArqueacao(e.target.checked);
  };  

  const handleMotivadoChange = (e) => {
    const { name, value } = e.target;
    setMotivadoData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleMotivadoClick = (e) => {
    setMotivadoData({
        COD_MOTIVADO: e.COD_MOTIVADO,
        NOME_MOTIVADO: e.NOME_MOTIVADO,
        CPF_MOTIVADO: e.CPF_MOTIVADO,
        EMAIL_MOTIVADO: e.EMAIL_MOTIVADO,
        TELEFONE: e.TELEFONE,
        NOME_REDUZIDO: e.NOME_REDUZIDO
    })
    setMotivadoModal(true)
  }

  const handleAlterarMotivado = async () => {
    try {
      const response = await Axios.put(`/api/motivado/alterar`, motivadoData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        enqueueSnackbar('Cadastro alterado com sucesso', { variant: 'success' });
        setMotivados((prevMotivados) =>
          prevMotivados.map((item) => {
            
            const updatedItem = item.COD_MOTIVADO === motivadoData.COD_MOTIVADO ? { ...item, ...motivadoData } : item;            
            return updatedItem;
          })
        );
        setMotivadoModal(false);
      } else {
        enqueueSnackbar('Algo deu errado ao alterar o cadastro', { variant: 'warning' });
      }
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: 'error' });
      console.error("Erro ao alterar o cadastro:", error);
    }
  };
  
  
  return (
    <>
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={`${style.active} ${style.navItem}`}>
              <i className="fa fa-home"></i> Motivados
            </div>
            <div className={style.navItem} onClick={() => navigate('/motivacao/historicomotivacao')}>
              <i className="fa fa-history"></i> Histórico
            </div>
            <div className={style.navItem} onClick={() => navigate('/motivacao/cadastro')}>
              <i className="fa fa-user-plus"></i> Cadastro
            </div>
          </div>

          <div className={style.filtersContainer}>
          <select
            className={style.selectEmpresa}
            value={empresaSelecionada}
            onChange={(e) => setEmpresaSelecionada(e.target.value)}
          >
            {empresasUnicas.map((empresa, index) => (
              <option key={index} value={empresa}>{empresa}</option>
            ))}
          </select>
            <div className={style.datePickerContainer}>
              <div className={`${style.dateRangeLabel} ${style.centeredLabel}`} onClick={() => setShowDatePicker(true)}>
                {startDate && endDate
                  ? `${startDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })} - ${endDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })}`
                  : 'Selecione o período'}
              </div>
              {showDatePicker && (
                <div className={style.datePickerWrapper}>
                  <DatePicker
                    selected={startDate}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      setStartDate(start);
                      setEndDate(end);
                      if (start && end) {
                        setShowDatePicker(false);
                      }
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    monthsShown={1}
                    locale="pt-BR"
                    dateFormat="dd/MM/yy"
                    placeholderText="Selecione o período"
                  />
                </div>
              )}
            </div>

            <div className={style.bercoContainer}>
              <Select 
              closeMenuOnSelect={false}
              components={makeAnimated()}
              isMulti
              styles={customStyles}
              options={bercospa.map(item => ({
                value: item.COD_BERCO_SPA,
                label: item.DESC_BERCO_SPA
              }))}
              onChange={(selectedOption) => handleBercosChange(selectedOption)}
              />

            </div>

            <label className={style.arqueacaoLabel}>
              <input
                type="checkbox"
                checked={arqueacao}
                onChange={handleArqueacaoChange}
                // Remova o disabled para testar a exibição do alerta
              />
              <span className={style.squareCheckbox}></span> Arqueação
            </label>

            
            <button className={style.addButton} onClick={toggleMostrarNavios1}>{mostrarNavios1 ? '-' : '+'}</button>
          </div>


          <div className={style.filtersContainerop}>

            <div className={style.selectButtonGroup}>
              {mostrarNavios1 && (
                <>
                  <select className={style.selectEmpresa} onChange={(e) => setOperacao1(e.target.value)}>
                    <option value="" disabled selected>Escolha uma opção</option>
                    {navios?.map((navio) => (
                      <option key={navio.NOME_NAVIO} value={navio.NOME_NAVIO}>{navio.NOME_NAVIO}</option>
                    ))}
                  </select>
                  <button className={style.addButton} onClick={toggleMostrarNavios2}>{mostrarNavios2 ? '-' : '+'}</button>
                </>
              )}

              {mostrarNavios2 && (
                <>
                  <select className={style.selectEmpresa} onChange={(e) => setOperacao2(e.target.value)}>
                    <option value="" disabled selected>Escolha uma opção</option>
                    {navios?.map((navio) => (
                      <option key={navio.NOME_NAVIO} value={navio.NOME_NAVIO}>{navio.NOME_NAVIO}</option>
                    ))}
                  </select>
                  <button className={style.addButton} onClick={toggleMostrarNavios3}>{mostrarNavios3 ? '-' : '+'}</button>
                </>
              )}

              {mostrarNavios3 && (
                <>
                  <select className={style.selectEmpresa} onChange={(e) => setOperacao3(e.target.value)}>
                    <option value="" disabled selected>Escolha uma opção</option>
                    {navios?.map((navio) => (
                      <option key={navio.NOME_NAVIO} value={navio.NOME_NAVIO}>{navio.NOME_NAVIO}</option>
                    ))}
                  </select>
                  <button className={style.addButton} onClick={toggleMostrarNavios4}>{mostrarNavios4 ? '-' : '+'}</button>
                </>
              )}

              {mostrarNavios4 && (
                <>
                  <select className={style.selectEmpresa} onChange={(e) => setOperacao4(e.target.value)}>
                    <option value="" disabled selected>Escolha uma opção</option>
                    {navios?.map((navio) => (
                      <option key={navio.NOME_NAVIO} value={navio.NOME_NAVIO}>{navio.NOME_NAVIO}</option>
                    ))}
                  </select>
                </>
              )}
            </div>
          </div>

          <div className={style.justificativaContainer}>
            <label htmlFor="justificativa">Justificativa:</label>
            <textarea
              id="justificativa"
              className={style.justificativaInput}
              value={justificativa}
              onChange={(e) => setJustificativa(e.target.value)}
            />
            .
            <label htmlFor="justificativa">Nomeação:</label>
            <div className={style.nomeacao}>
              <ImageUpload onUpload={onUpload} />
              <label className={style.arqueacaoLabel}>
              <input
                type="checkbox"
                checked={!isNomeacaoRequired}
                onChange={handleNomeacaoReq}
                // Remova o disabled para testar a exibição do alerta
              />
              <span className={style.squareCheckbox}></span> Empresa nomeada pela Orion
            </label>
            </div>
            
            {isMotivarButtonHidden ? (
              <div className={style.aguardeMotivar}>Aguarde Motivar...</div>
            ) : (
              <div className={style.motivarButtonInputContainer}>
                <SubmitButton text="Motivar" onClick={validaDados} onKeyPress={validaTecla} />
                <div className={style.cpfInputContainer}>
                  <input
                    type="text"
                    id="cpfInput"
                    className={style.cpfInput}
                    placeholder="Digite os CPFs"
                    onBlur={(e) => ativarCPFs(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        ativarCPFs(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className={style.dataFiltro}>
            <FaFileExcel
              onClick={exportToExcel}
              style={{ fontSize: '1.5em', color: 'green', cursor: 'pointer' }}
            />
          </div>

          <div className={style.tableContainer}>
            <table className={style.table}>
              <thead>
                <tr>
                  <th>NOME MOTIVADO</th>
                  <th>CPF MOTIVADO</th>
                  <th>TELEFONE</th>
                  <th>E-MAIL</th>
                  <th>EMPRESA</th>
                  <th>
                    <div onClick={toggleSelectAll}>
                      {selectAll ? <FaCheckSquare /> : <FaSquare />}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredMotivados.map((motivado) => (
                  <tr key={motivado.COD_MOTIVADO}>
                    <td onClick={() => handleMotivadoClick(motivado)}>{motivado.NOME_MOTIVADO}</td>
                    <td onClick={() => handleMotivadoClick(motivado)}>{formatarCPF(motivado.CPF_MOTIVADO)}</td>
                    <td onClick={() => handleMotivadoClick(motivado)}>{motivado.TELEFONE}</td>
                    <td onClick={() => handleMotivadoClick(motivado)}>{motivado.EMAIL_MOTIVADO}</td>
                    <td onClick={() => handleMotivadoClick(motivado)}>{motivado.NOME_REDUZIDO}</td>
                    <td>{renderStatusSwitch(motivado)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal
            isOpen={motivadoModal}
            onRequestClose={() => setMotivadoModal(false)}
            contentLabel="Motivado"
            className={modalStyle.modal2}
            overlayClassName={modalStyle.overlay}
          >
            <label>Nome do motivado</label>
            <input
              className={style.motivadoInput}
              name="NOME_MOTIVADO"
              value={motivadoData.NOME_MOTIVADO}
              onChange={handleMotivadoChange}
              placeholder="Digite o nome"
            />

            <label>CPF do motivado</label>
            <input
              className={style.motivadoInput}
              name="CPF_MOTIVADO"
              value={motivadoData.CPF_MOTIVADO}
              onChange={handleMotivadoChange}
              placeholder="Digite o CPF"
            />

            <label>Email do motivado</label>
            <input
              className={style.motivadoInput}
              name="EMAIL_MOTIVADO"
              value={motivadoData.EMAIL_MOTIVADO}
              onChange={handleMotivadoChange}
              placeholder="Digite o email"
            />

            <label>Telefone do motivado</label>
            <input
              className={style.motivadoInput}
              name="TELEFONE"
              value={motivadoData.TELEFONE}
              onChange={handleMotivadoChange}
              placeholder="Digite o telefone"
            />

            <label>Empresa do motivado</label>
            <select
              className={style.motivadoInput}
              value={motivadoData.NOME_REDUZIDO}
              name="NOME_REDUZIDO"
              onChange={handleMotivadoChange}
            >
              {empresasUnicas.map((empresa, index) => (
                <option key={index} value={empresa}>{empresa}</option>
              ))}
            </select>
            <div className={modalStyle.modalButtons}>
              <button onClick={() => setMotivadoModal(false)} className={`${modalStyle.modalButton} ${modalStyle.modalCancel}`}>Cancelar</button>
              <button onClick={() => handleAlterarMotivado()} className={`${modalStyle.modalButton} ${modalStyle.modalSave}`}>Salvar</button>
            </div>
          </Modal>


          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="CPFs não encontrados"
            className={modalStyle.modal}
            overlayClassName={modalStyle.overlay}
          >
            <div className={modalStyle.nav}>
              <div className={modalStyle.active}>
                <i className="fa fa-exclamation-circle"></i> CPFs Não Encontrados!
              </div>
            </div>
            <ul>
              <div className={modalStyle.cpf}>
                {notFoundCpfs.map((cpf, index) => (
                  <li key={index}>- {formatarCPF(cpf)}</li>
                ))}
              </div>
            </ul>
            <SubmitButton text="Fechar" onClick={() => setModalIsOpen(false)} />
          </Modal>

        </div>
      </Container>
    </>
  );
};

const MotivadosWithSnackbar = () => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <Motivados />
  </SnackbarProvider>
);

export default MotivadosWithSnackbar;
